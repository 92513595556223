import React, { useEffect, useState, } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm, UseFormSetError, FieldValues } from "react-hook-form";

import Form from "../../components/Form";
import Input from "../../components/Input";
import Button from "../../components/Button";

import { toastify } from "../../utils/indexTS";
import useOverlay from "../../hooks/useOverlay";
import httpRequest from "../../services/httpRequest";
import { RESET_PASSWORD_URL, VERIFY_PASSWORD_URL } from "../../constants/urls";

interface IResetPasswordInput {
  newPassword: string;
  passwordToken: string;
  confirmNewPassword: string;
}

type ResetPasswordParamsType = {
  token: string;
}

let globalSetError: any = null;

const ResetPassword: React.FC = () => {
  const methods = useForm();
  const history = useHistory();
  const { token } = useParams<ResetPasswordParamsType>();
  const { hideOverlay } = useOverlay();
  const [loading, setLoading] = useState(false);

  const verifyToken = async () => {
    const response = await httpRequest(VERIFY_PASSWORD_URL, "post", { passwordToken: token }, false);

    if (!response) {
      history.push("/login");
    }
  };

  const onSubmit = async (data: IResetPasswordInput) => {
    setLoading(true);

    if (data.newPassword !== data.confirmNewPassword) {
      toastify("error", "As senhas devem ser iguais");
      globalSetError("password");
      globalSetError("confirmPassword");
      setLoading(false);
      return;
    }

    // eslint-disable-next-line no-param-reassign
    data.passwordToken = token;
    const response = await httpRequest(RESET_PASSWORD_URL, "post", data, false);

    setLoading(false);

    if (response) {

      toastify("success", "Senha redefinida com sucesso.");
      toastify("success", "(Desenvolvimento) Você será redirecionado");

      history.push("/login");
    }

  };

  const callbackError = (setError: UseFormSetError<FieldValues>) => {
    globalSetError = setError;
  };

  useEffect(() => {
    verifyToken();
    hideOverlay();
  }, []);

  return (
    <div className="main">
      <div
        className="login-page bg-image"
        style={{
          display: "flex",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/auth.jpg)`,
          height: "100vh"
        }}
      >
        <div className="container" style={{ display: "flex", alignItems: "center" }}>
          <div className="form-box" style={{ maxHeight: 600, borderRadius: 8 }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/redlogo.png`}
              alt="Logo Sandaliaria"
              width="300"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20
              }}
            />
            <h1 className="text-center auth-title">Portal do Franqueado</h1>
            <p className="text-center mb-1 auth-content">
              Crie uma nova senha entre 8 e 14 caracteres.
            </p>
            <Form
              methods={methods}
              submitHandler={onSubmit}
              callbackError={callbackError}
            >
              <div className="form-group">
                <Input
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="Nova senha"
                  rules={{
                    required: true,
                    minLength: 8,
                    maxLength: 14
                  }}
                />
              </div>

              <div className="form-group">
                <Input
                  className="form-control"
                  id="confirmNewPassword"
                  placeholder="Confirme sua nova senha"
                  name="confirmNewPassword"
                  type="password"
                  rules={{
                    required: true,
                    minLength: 8,
                    maxLength: 14
                  }}
                />
              </div>

              <div className="form-footer">
                <Button
                  text="Criar nova senha"
                  icon="icon-long-arrow-right"
                  loading={loading}
                  submit
                />
                <Link to="/login">Voltar ao login</Link>
              </div>
            </Form>
          </div>
        </div>
        <p style={{
          position: "absolute",
          bottom: 0,
          right: 8,
          color: "#fff",
          fontSize: 13,
          textAlign: "center"
        }}
        >
          Desenvolvido com
          {" "}
          <i className="fas fa-heart" />
          {" "}
          por
          {" "}
          <a
            href="https://fbenevides.com.br"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "underline",
              color: "#fff",
              fontSize: "13px !important"
            }}
          >
            Fbenevides Tecnologia
          </a>
        </p>
      </div>
    </div>
  );
};

export default React.memo(ResetPassword);
