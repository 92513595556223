import React from "react";

type AlertProps = {
  type: string;
  title: string;
  message: string;
  className: string;
  eventClose: () => void;
  withoutCloseBtn?: boolean;
};

const Alert: React.FC<AlertProps> = ({
  type,
  title,
  message,
  className,
  eventClose,
  withoutCloseBtn = false
}: AlertProps) => (
  <div
    className={`alert alert-${type} alert-dismissible fade show ${className}`}
    role="alert"
  >
    <strong>{`${title} `}</strong>
    <br />
    {message}
    {!withoutCloseBtn && (
      <button
        type="button"
        onClick={() => eventClose()}
        className="close"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    )}
  </div>
);

export default Alert;
