import * as types from '../constants/action-types'
import { findIndex } from "../utils";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
  page: 1,
  name: "",
  brand: "",
  sortBy: "",
  ready: false,
  changed: false,
  categories: [],
  attributes: [],
  subcategories: [],
  paymentConditionId: '',
  values: { max: 0, min: 0 }
}

function filterReducer( state = initialState, action ) {
  switch (action.type) {
    case types.SET_FILTER:
      const { filters } = action

      return {
        ...state,
        ...filters
      }

    case types.SORT_BY:
      return {
        ...state,
        changed: true,
        sortBy: action.sortBy
      };

    case types.PAYMENT_FILTER:
      return {
        ...state,
        changed: true,
        paymentConditionId: action.paymentId
      }

    case types.RESET_FILTER:
      const categories = state.categories.map((category) => ({ ...category, checked: false }))
      const subcategories = state.subcategories.map((subcategory) => ({ ...subcategory, checked: false }))
      const attributes = state.attributes.map((attribute) => ({
        ...attribute,
        attributesValues: attribute.attributesValues.map((value) => ({...value, checked: false }))
      }))

      return {
        page: 1,
        sortBy: "",
        categories,
        attributes,
        ready: false,
        subcategories,
        changed: false,
        values: { max: 0, min: 0 },
        name: action.clearName ? "" : state.name,
        paymentConditionId: action.clearPaymentCondition ? '': state.paymentConditionId,
      };

    case types.BRAND_FILTER:
      let brands = Object.assign( [], state.brands );
      index = findIndex( brands, item => item === action.brands );
      if ( -1 === index ) {
        brands.push( action.brands );
      } else {
        brands.splice( index, 1 );
      }
      return {
        ...state,
        brands: brands
      };

    case types.REFRESH_STORE:
      return { state: initialState };

    case types.PRICE_FILTER:
      return {
        ...state,
        changed: true,
        values: {
          ...action.range
        }
      };

    case types.CHANGE_PAGE:
      const { page } = action

      return {
        ...state,
        page
      }
    default:
      return state;
    }
}

const persistConfig = {
    keyPrefix: 'sandaliaria-',
    key: "filter",
    storage
}

export default persistReducer( persistConfig, filterReducer );
