import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { CHANGE_USER_FRANCHISEE } from "../constants/action-types"

const initialState = {
  name: '',
  secureId: '',
  groupSecureId: '',
}

function userAdminFranchiseReducer(state = initialState, action) {
  if (action.type === CHANGE_USER_FRANCHISEE) {
    return {
      ...state,
      name: action.franchiseeName,
      secureId: action.franchiseeSecureId,
      groupSecureId: action.groupSecureId
    }
  }

  return state
}

const persistConfig = {
  prefix: 'user',
  key: 'franchisee',
  storage
}

export default persistReducer(persistConfig, userAdminFranchiseReducer)
