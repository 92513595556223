import { CHANGE_THEME } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    theme: {
        primaryColor: "#c33",
        secondaryColor: "#ae2b2b",
        darkColor: "#222",
        lightColor: "#fff"
    }
};

const themeReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case CHANGE_THEME:
            return {
                ...state,
                theme: action.theme
            };

        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "sabndaliaria-",
    key: "theme",
    storage
}

export default persistReducer( persistConfig, themeReducer );