import { USER_KEY } from "../constants/storage";
import { LOGIN, LOGOUT } from "../constants/action-types";
import { getStorage, setStorage, clearStorage } from '../services/storage'

let userContent = getStorage(USER_KEY)

const initialState = {
  user: userContent ? userContent : null
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      const { user, rememberMe } = action;
      setStorage(rememberMe, USER_KEY, user);
      return { user };

    case LOGOUT:
      clearStorage(USER_KEY)
      return { user: null }

    default:
      return state;
  }
}

export default authReducer;
