import { toast } from "react-toastify";

const options = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000
};

export function toastify(type: string, message: string): void {
  switch (type) {
    case "success":
      toast.success(message, options);
      break;
    case "error":
      toast.error(message, options);
      break;
    case "warning":
      toast.warn(message, options);
      break;
    default:
      toast.info(message, options);
      break;
  }
}

export function changeColor(color: string): void {
    const a = document.getElementsByClassName('intro-section') as HTMLCollectionOf<HTMLElement>;
    a[0].style.backgroundColor = color;
}


export function convertListBrand(brandList: any): any {

  let brandListFormated: Array<any> = [];
  let brandListLength: number = 0;
  let index: number = 0;

  brandListLength = brandList.length > 0 ? brandList.length == 1 ? 1 : Math.ceil(brandList.length / 2) : 0

  for(let i = 0; i < brandListLength; i++)
      brandListFormated.push([]);

  brandList.forEach((item: any) => {
    if(brandListFormated[index].length == 2){
      index++;
    }

    brandListFormated[index].push(item);
  });

  return brandListFormated;
};
