import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// import Custom Components

function ErrorPage() {
  const logo = "assets/images/logo.png";

  return (
    <div className="main">
      <Helmet>
        <title>Sandaliaria - Página Não Encontrada</title>
      </Helmet>

      <div className="error-content text-center">
        <div className="container">
          <div className="row justify-content-center" style={{ padding: "20px 0" }}>
            <div className="col-12 col-sm-auto d-flex justify-content-center justify-content-sm-end">
              <img src={`${process.env.PUBLIC_URL}/assets/images/redlogo.png`} alt="Sandaliaria Logo" width="180" />
            </div>
            <div className="col-12 col-sm-auto">
              <p style={{
                margin: "auto 0",
                marginLeft: "2rem"
              }}
              >
                Não encontramos o que você procura!
              </p>
            </div>
          </div>
          <Link to="/" className="btn btn-outline-primary-2 btn-minwidth-lg" style={{ marginBottom: "12rem" }}>
            <span>Voltar para Home</span>
            <i className="icon-long-arrow-right" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ErrorPage);
