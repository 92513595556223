import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import {
  CHANGE_QTY,
  UPDATE_LIST,
  ADD_TO_CART,
  CHANGE_SHIPPING,
  REMOVE_FROM_CART,
  UPDATE_VARIATIONS,
  REFRESH_CART_STORE,
  CHANGE_PAYMENT_CONDITION
} from "../constants/action-types";

import {
  findIndex,
  quantitySmallMin,
  makeDeepCopyObject,
  totalProductVariant,
  parseMaxQtyOrInfinity,
  getPaymentConditionValue,
} from "../utils";

const initialState = {
  list: [],
  brandSlug: '',
  shipping: "free",
  orderSecureId: '',
  paymentCondition: {}
}

function validateProductQty (product, qty, maxQuantity) {
  const typesToChange = ['outOfStock', 'variations', 'multiple', 'minimum']

  switch (product.available) {
    case true:
      const isMultiple = !(qty % product.multiple)

      product.typeOfUnavailability = !isMultiple
        ? typesToChange[2]
        : 'none'

        product.typeOfUnavailability = isMultiple && quantitySmallMin(qty, product.minimumQuantity)
          ? typesToChange[3]
          : product.typeOfUnavailability


      product.available = !product.typeOfUnavailability || product.typeOfUnavailability === 'none'
        ? true
        : false

      return;
    case false:
      if (!typesToChange.includes(product.typeOfUnavailability)) return

      product.available = qty % product.multiple === 0 && qty <= parseMaxQtyOrInfinity(maxQuantity)

    product.typeOfUnavailability = !product.available
      ? product.typeOfUnavailability
      : 'none'
      return;

    default:
      return;
  }
}

function getProductPrice (product, qty) {
  return product.isVariable
    ? totalProductVariant(product)
    : getPaymentConditionValue(product.paymentCondition) * qty
}

function cartReducer(state = initialState, action) {

  switch (action.type) {
    case ADD_TO_CART:
      const productAction = action.product

      if (state.list.length < 1) {

        return {
          ...state,
          brandSlug: productAction.brandSlug,
          paymentCondition: {
            name: productAction.paymentCondition.name,
            secureId: productAction.paymentCondition.secureId
          },
          list: [
            {
              ...productAction,
              qty: action.qty,
              sum: getProductPrice(productAction, action.qty)
            }
          ]
        }
      }

      if (action.replaceIndex > -1) {
        const listCopy = makeDeepCopyObject(state.list)

        listCopy[action.replaceIndex] = null
        listCopy[action.replaceIndex] = {
          ...productAction,
          available: true,
          qty: action.qty,
          typeOfUnavailability: 'none',
          sum: getProductPrice(productAction)
        }

        return { ...state, list: listCopy }
      }

      if (findIndex(state.list, product => product.secureId === productAction.secureId) !== -1) {
        const list = state.list.reduce((cartAcc, product) => {
          if (product.secureId === productAction.secureId) {

            let newQty = product.qty + action.qty
            newQty = newQty > parseMaxQtyOrInfinity(productAction.maxQuantity)
              ? product.qty
              : newQty

            let productUpdated = { ...product }

            if (product.isVariable) {
              productUpdated.qty = 0;
              productUpdated.sum = 0;

              //1. Para cada variação que vem (variation) quando adiciono no carrinho (Detail-One.jsx)
              productAction.productVariations.forEach((variation) => {
                let contains = false;

                //2. Verifico com cada variação que já está no carrinho
                for(let i = 0; i < product.productVariations.length; i++) {

                  //3. Pego os atributos da variation e da productVariations
                  const prodActionVariantAttributes = variation.attributes;
                  const prodVariantAttributes = product.productVariations[i].attributes;

                  //4. Comparo os atributos, se qualquer um der falso, eu saio do laço porque sei que não são iguais
                  let attributeEquals = true
                  for (let j = 0; j < prodActionVariantAttributes.length; j++) {
                    attributeEquals = attributeEquals && prodActionVariantAttributes[j].value.value === prodVariantAttributes[j].value.value

                    if (!attributeEquals) break;
                  }

                  contains = attributeEquals

                  //5. Se actionProdVariant já existir no carrinho, apenas atualizo
                  if (contains) {
                    let newVariationQty = product.productVariations[i].data.quantity + variation.data.quantity
                    newVariationQty = newVariationQty > parseMaxQtyOrInfinity(productAction.maxQuantity) ?
                      variation.data.quantity : newVariationQty

                    product.productVariations[i].data.quantity = newVariationQty
                    product.productVariations[i].data.paymentCondition = variation.data.paymentCondition
                    product.productVariations[i].data.totalValue = variation.data.unitValue * newVariationQty
                    break;
                  }
                }

                //6. Senão existir, adidiciono
                if (!contains) {
                  product.productVariations.push(variation)
                  return;
                }
              })

              productUpdated.qty += product.productVariations
                .map((variation) => variation.data.quantity)
                .reduce((total, currentQuantity) => total + currentQuantity)

              productUpdated.sum += product.productVariations
                .map((variation) => variation.data.totalValue)
                .reduce((total, currentTotal) => total + currentTotal)

              productUpdated.productVariations = [...product.productVariations]
            } else {
              productUpdated.qty = newQty,
              productUpdated.sum = getPaymentConditionValue(productAction.paymentCondition) * newQty
            }

            productUpdated.available = true
            productUpdated.typeOfUnavailability = 'none'

            cartAcc.push(productUpdated)
          } else {
            cartAcc.push(product)
          }

          return cartAcc
        }, [] )

        return { ...state, list }
      }

      return {
        ...state,
        list: [
          ...state.list,
          {
            ...productAction,
            qty:  action.qty,
            sum: getProductPrice(productAction, action.qty)
          }
        ]
      }

    case REMOVE_FROM_CART:
      const newCartList = state.list.filter(item => item.secureId !== action.productSecureId)

      return {
        ...state,
        list: newCartList,
        brandSlug: !newCartList.length ? '' : state.brandSlug,
        paymentCondition: !newCartList.length ? {} : state.paymentCondition
      };

    case CHANGE_QTY:
      const list = state.list.reduce((cartAcc, product) => {
        if (product.secureId === action.secureId) {

          validateProductQty(product, action.qty, product.maxQuantity)

          cartAcc.push({
            ...product,
            qty: action.qty,
            sum: getPaymentConditionValue(product.paymentCondition) * action.qty
          })
        } else {
          cartAcc.push(product)
        }
        return cartAcc;
      }, [])

      return { ...state, list };

    case UPDATE_VARIATIONS:
      let listUpdated

      if (!action.product.productVariations.length) {
        listUpdated = state.list.filter((product) => product.secureId !== action.product.secureId)
      } else {
        listUpdated = state.list.reduce((cartAcc, product) => {
          if (product.secureId === action.product.secureId) {
            const qty = action.product.productVariations
              .map((variant) => variant.data.quantity)
              .reduce((total, currentQntity) => total + currentQntity);

            validateProductQty(product, qty, qty)

            cartAcc.push({
              ...product,
              qty,
              sum: totalProductVariant(action.product),
              productVariations: [...action.product.productVariations],
            })
          } else {
            cartAcc.push( product )
          }
          return cartAcc;
        }, [])
      }


      return { ...state, list: listUpdated };

    case UPDATE_LIST:
      // console.log('action.list', action.list)
      // return state
      return {
        list: action.list,
        brandSlug: action.brandSlug ? action.brandSlug : state.brandSlug,
        orderSecureId: action.orderSecureId ? action.orderSecureId : state.orderSecureId,
        paymentCondition: action.paymentCondition ? action.paymentCondition : state.paymentCondition
      };

    case REFRESH_CART_STORE:
      return { list: [], shipping: "free", brandSlug: "", orderSecureId: "", paymentCondition: {} };

    case CHANGE_SHIPPING:
      return { ...state, shipping: action.shipping };

    case CHANGE_PAYMENT_CONDITION:
      return { ...state, paymentCondition: { ...state.paymentCondition, name: action.paymentCondition.name } }

    default:
      return state;
  }
}

const persistConfig = {
    keyPrefix: "molla-",
    key: "cartlist",
    storage
}

export default persistReducer( persistConfig, cartReducer );
