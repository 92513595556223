import { toast } from 'react-toastify';
import * as types from '../constants/action-types'
import 'react-toastify/dist/ReactToastify.min.css';

// refresh local storage

export const refreshUnSafe = ( current ) => ( {
    type: types.REFRESH_STORE,
    current
} )

export const refreshStore = ( current ) => dispatch => {
    dispatch( refreshUnSafe( current ) );
}

/*********** Session actions **********/
export const login = (user, rememberMe) => dispatch => {
  dispatch({
    type: types.LOGIN,
    user,
    rememberMe
  })
}

export const logout = () => dispatch => {
  dispatch({
    type: types.LOGOUT
  })
}

/*********** UserAdmin actions **********/
export const changeUserFranchisee = (groupSecureId, franchiseeName, franchiseeSecureId) => dispatch => {
  dispatch({
    type: types.CHANGE_USER_FRANCHISEE,
    groupSecureId,
    franchiseeName,
    franchiseeSecureId
  })
}

/*********** Notifications action **********/
export const setUnreadNotificationsQty = (unreadQuantity) => dispatch => {
  dispatch({
    unreadQuantity,
    type: types.SET_UNREAD_NOTIFICATIONS_QTY
  })
}

export const setUpdateUnreadQuantity = (updateUnreadQuantity) => dispatch => {
  dispatch({
    updateUnreadQuantity,
    type: types.SET_UPDATE_NOTIFICATIONS_QTY
  })
}

/*********** Modal related Action **********/
// display quickview
export const showQuickViewModal = productId => ( {
    type: types.SHOW_QUICKVIEW,
    productId
})

// close quickview modal
export const closeQuickViewModal = () => ( {
  type: types.CLOSE_QUICKVIEW
})

/************ Cart Action **************/
// add item to cart
export const addToCart = (product, qty, replaceIndex) => (dispatch) => {
  toast.success("Produto adicionado ao carrinho");
  dispatch(addToCartUnsafe(product, qty, replaceIndex));
};

// add item to cart : typical action
export const addToCartUnsafe = (product, qty, replaceIndex) => ( {
  type: types.ADD_TO_CART,
  product,
  qty,
  replaceIndex,
});



// remove item from cart
export const removeFromCart = productSecureId => (dispatch) => {
  toast.success("Produto removido do carrinho");

  dispatch({
    type: types.REMOVE_FROM_CART,

    productSecureId
  })
};

export const refreshCartStore = () => (dispatch) => {
  dispatch({
    type: types.REFRESH_CART_STORE
  })
}

// change quantity product
export const changeProductQty = (secureId, qty) => (dispatch) => {
   dispatch({
    type: types.CHANGE_QTY,
    secureId,
    qty
  })
};

export const updatePaymentCondition = (name) => (dispatch) => {
  dispatch({
    type: types.CHANGE_PAYMENT_CONDITION,
    paymentCondition: { name }
  })
}

export const updateProductVariants = (product) => (dispatch) => {
  dispatch({
    type: types.UPDATE_VARIATIONS,
    product
  });
}

export const updateCart = (list, brandSlug, paymentCondition, orderSecureId) => (dispatch) => {
  dispatch({
    type: types.UPDATE_LIST,
    list,
    brandSlug,
    orderSecureId,
    paymentCondition,
  })
}

// change shipping method
export const changeShipping = ( shipping ) => ( {
    type: types.CHANGE_SHIPPING,
    shipping
})

/************** Filter Action ***********/

export const setFilter = (filters) => (dispatch) => {
  dispatch({
    type: types.SET_FILTER,
    filters
  })
}

// set order to sort
export const filterSort = ( sortBy ) => ( dispatch ) => {
    dispatch( {
        type: types.SORT_BY,
        sortBy
    } )
}

// set paymentId to filter
export const setPaymentFilter = (paymentId) => (dispatch) => {
  dispatch({
    type: types.PAYMENT_FILTER,
    paymentId
  })
}

// set price range to get suitable products
export const filterPrice = ( range ) => ( dispatch ) => {
    dispatch( {
        type: types.PRICE_FILTER,
        range
    } )
}

// add/remove brand to get suitable products
export const toggleBrandFilter = ( brand ) => ( dispatch ) => {
    dispatch( {
        type: types.BRAND_FILTER,
        brand
    } )
}

export const changePage = (page) => (dispatch) => {
  dispatch({
    type: types.CHANGE_PAGE,
    page
  })
}

// reset filter with intialstate
export const resetFilter = (clearPaymentCondition = true, clearName = true) => ( dispatch ) => {
    dispatch( {
        type: types.RESET_FILTER,
        clearName,
        clearPaymentCondition,
    } )
}

/************** Newsletter Modal ************/

// hide newsletter modal in homepage
export const hideNewsletterModal = () => ( {
    type: types.HIDE_NEWSLETTER_MODAL
} )
