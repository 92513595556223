import axios from 'axios';
import store from "../store";
// import { config } from 'process';
import { toastify } from "../utils/indexTS";
import { USER_KEY } from '../constants/storage';
import { getStorage } from '../services/storage';
import { REFRESH_TOKEN_URL, FORGET_PASSWORD_URL } from '../constants/urls';

const config = {
  headers: {
    Accept: "application/json"
  }
};

const axiosInstace = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  },
  timeout: 180000
})

export default async (url, method, data, authenticate = true, formData = false) => {
  if (authenticate) {
    const { token } = getStorage(USER_KEY)
    axiosInstace.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  formData
  ? (config.headers["Content-Type"] = "multipart/form-data")
  : (config.headers["Content-Type"] = "application/json");

  const response = await axiosInstace[method](url, data, config);
  return response;
}

async function refreshSession() {
  const { refreshToken } = getStorage(USER_KEY);
  return axiosInstace.post(REFRESH_TOKEN_URL, { refreshToken });
}

axiosInstace.interceptors.response.use((response) => {
  const { url } = response.config;

  if (url === FORGET_PASSWORD_URL && response.data.error) {
    return null;
  }

  if (response.data.error) {
    const { type, message } = response.data;

    if (type && message) toastify(type, message)

    return null;
  }

  return response.data;
}, async (error) => {
  if (error.message === 'Network Error') {
    toastify(
      "error",
      "Sem conexão com a internet, verifique sua conexão"
    );
    return null;
  }

  if(!error.response) {
    toastify('error', 'Erro de conexão')
    return null;
  }

  if(!error.response)
    return null;

  if (error.response.status === 500) {
    toastify('error', "Erro no servidor, favor comunicar o administrador do sistema");
    return null;
  }

  if (error.response.status !== 401) {
    toastify('error', "Erro de conexão");
    return null;
  }

  if (error.response.status === 401 && !error.config.retry) {

    // Deslogar o usuario
    store.dispatch({
      type: "LOGOUT"
    })

    // ToDo: Refazer a logica usando Redux-Saga para enfileirar as requests
    // return refreshSession().then((data) => {
    //   console.log("Resposta do data: ", data);
    //   updateStorage(USER_KEY, data);

    //   error.config.retry = true;
    //   error.config.headers.Authorization = "Bearer " + data.token;
    //   return axiosInstace(error.config);
    // })
  }

  return Promise.reject(error);
});
