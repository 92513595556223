import { SET_UNREAD_NOTIFICATIONS_QTY, SET_UPDATE_NOTIFICATIONS_QTY } from '../constants/action-types'

const initialState = {
  unreadQuantity: null,
  updateUnreadQuantity: false,
}

export default function notificationsReducer(state = initialState, action) {
  switch(action.type) {
    case SET_UNREAD_NOTIFICATIONS_QTY:
      const { unreadQuantity } = action
      
      return { ...state, unreadQuantity }
    case SET_UPDATE_NOTIFICATIONS_QTY:
      const { updateUnreadQuantity } = action

      return { ...state, updateUnreadQuantity }
    default: 
      return state;
  }
}