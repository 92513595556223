import React, { useEffect, ReactNode } from "react";
import {
  FormProvider,
  UseFormSetError,
  FieldValues
} from "react-hook-form";

// ToDo: Ajustar no ESlint para não ficar vermelho
type FormProps = {
  style?: Object;
  children: ReactNode;
  submitHandler: (data: any) => void;
  callbackError?: (setError: UseFormSetError<FieldValues>) => void;
  methods: any;
};

const Form: React.FC<FormProps> = ({
  style,
  children,
  submitHandler,
  callbackError,
  methods

}: FormProps) => {

  useEffect(() => {
    if (callbackError) {
      callbackError(methods.setError);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)} style={style}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
