/* eslint-disable react/destructuring-assignment */
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Form from "../../components/Form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import useOverlay from "../../hooks/useOverlay";
import { LOGIN_URL } from "../../constants/urls";
import httpRequest from "../../services/httpRequest";
import { login, changeUserFranchisee } from "../../actions";

interface ILoginInput {
  email: string;
  password: string;
  rememberMe: boolean;
}

type propsLogin = {
  login: Function;
  changeUserFranchisee: Function;
  user: Object;
};

const Login = (props: propsLogin) => {
  const history = useHistory();

  if (props.user) {
    history.push("/");
  }

  const [loading] = useState(false);

  const { hideOverlay } = useOverlay();

  const methods = useForm();

  const onSubmit = async (values: ILoginInput) => {
    const data = {
      email: values.email.trim(),
      password: values.password.trim(),
      type: "franchisee"
    };

    const response = await httpRequest(LOGIN_URL, "post", data, false);

    if (response) {
      props.changeUserFranchisee("", "", "");
      props.login(response, values.rememberMe);
      history.push("/");
    }
  };

  useEffect(() => {
    hideOverlay();
  }, []);

  return (
    <div className="main">
      <div
        className="login-page bg-image"
        style={{
          display: "flex",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/auth.jpg)`,
          height: "100vh"
        }}
      >
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="form-box" style={{ maxHeight: 600, borderRadius: 8 }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/redlogo.png`}
              alt="Logo Sandaliaria"
              width="300"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20
              }}
            />
            <h1 className="text-center auth-title">Portal do Franqueado</h1>
            <Form submitHandler={onSubmit} methods={methods}>
              <div className="form-group">
                <Input
                  className="form-control"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  rules={{
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  }}
                />
              </div>

              <div className="form-group">
                <Input
                  className="form-control"
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Senha"
                  rules={{
                    required: true,
                    minLength: 8,
                    maxLength: 14
                  }}
                />
              </div>

              <div className="form-footer">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="signin-remember"
                    {...methods.register("rememberMe")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="signin-remember"
                  >
                    Lembrar-me
                  </label>
                </div>

                <Button
                  text="Entrar"
                  icon="icon-long-arrow-right"
                  loading={loading}
                  submit
                />
              </div>

              <div className="form-footer-flex-end">
                <Link to="/recuperar-senha" style={{ fontSize: "1.3rem" }}>
                  Esqueceu a senha?
                </Link>
              </div>
            </Form>
          </div>
        </div>
        <p
          style={{
            position: "absolute",
            bottom: 0,
            right: 8,
            color: "#fff",
            fontSize: 13,
            textAlign: "center"
          }}
        >
          {/* eslint-disable-next-line */}
          Desenvolvido com <i className="fas fa-heart" /> por{" "}
          <a
            href="https://fbenevides.com.br"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "underline",
              color: "#fff",
              fontSize: "13px !important"
            }}
          >
            Fbenevides Tecnologia
          </a>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  userAdminFranchisee: state.userAdminFranchisee
});

export default connect(mapStateToProps, { login, changeUserFranchisee })(Login);
