import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Form from "../../components/Form";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import Button from "../../components/Button";

import useOverlay from "../../hooks/useOverlay";
import httpRequest from "../../services/httpRequest";
import { FORGET_PASSWORD_URL } from "../../constants/urls";

interface IForgetPasswordInput {
  type: string;
  email: string;
}

const ForgetPassword: React.FC = () => {
  const { hideOverlay } = useOverlay();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    type: "",
    title: "",
    message: ""
  });

  const methods = useForm();

  const onSubmit = async (data: IForgetPasswordInput) => {
    setLoading(true);

    // eslint-disable-next-line no-param-reassign
    data.type = "franchise";
    const response = await httpRequest(FORGET_PASSWORD_URL, "post", data, false);

    setLoading(false);

    if (!response) {
      setAlertProps({
        type: "danger",
        title: "Atenção!",
        message: `Este email não pertence á uma conta válida do
                portal do franqueado da sandaliaria,
                certifique-se que seu email está correto e tente
                novamente.`
      });
    } else {
      setAlertProps({
        type: "success",
        title: "Sucesso!",
        message: `O link de recupeção de senha foi enviado para o email: ${data.email}`
      });

    }

    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    hideOverlay();
  }, []);

  return (
    <div className="main">
      <div
        className="login-page bg-image "
        style={{
          display: "flex",
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/auth.jpg)`,
          height: "100vh"
        }}
      >
        <div className="container" style={{ display: "flex", alignItems: "center" }}>
          <div className="form-box" style={{ maxHeight: 600, borderRadius: 8 }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/redlogo.png`}
              alt="Logo Sandaliaria"
              width="300"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20
              }}
            />
            <h1 className="text-center auth-title">Recuperação de Senha</h1>
            <p className="text-center mb-1 auth-content">
              Digite seu email abaixo, para receber o link com a
              recuperação de senha.
            </p>
            {showAlert && (
              <Alert
                type={alertProps.type}
                title={alertProps.title}
                message={alertProps.message}
                eventClose={closeAlert}
                className="mb-1"
              />
            )}
            <Form submitHandler={onSubmit} methods={methods}>
              <div className="form-group">
                <Input
                  className="form-control"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Digite seu email..."
                  rules={{
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  }}
                />
              </div>

              <div className="form-footer">
                <Button
                  text="Enviar link de recuperação"
                  icon="icon-long-arrow-right"
                  loading={loading}
                  submit
                />
                <Link to="/login">Voltar ao login</Link>
              </div>
            </Form>
          </div>
        </div>
        <p style={{
          position: "absolute",
          bottom: 0,
          right: 8,
          color: "#fff",
          fontSize: 13,
          textAlign: "center"
        }}
        >
          Desenvolvido com
          {" "}
          <i className="fas fa-heart" />
          {" "}
          por
          {" "}
          <a
            href="https://fbenevides.com.br"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "underline",
              color: "#fff",
              fontSize: "13px !important"
            }}
          >
            Fbenevides Tecnologia
          </a>
        </p>
      </div>
    </div>
  );
};

export default React.memo(ForgetPassword);
