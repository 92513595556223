import { combineReducers } from 'redux';

// Import custom components
import authReducer from './auth'
import cartReducer from './cart';
import themeReducer from "./theme";
import filterReducer from './filter';
import userAdminFranchisee from './userAdminFranchisee';
import notificationReducer from './notifications';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  userAdminFranchisee,
  theme: themeReducer,
  filters: filterReducer,
  notifications: notificationReducer,
});

export default rootReducer;
