import { useEffect, useRef } from "react";

function useOverlay() {
  const overlayRef = useRef<any>();

  function showOverlay() {
    const body = document.querySelector("body");

    if (body) {
      body.classList.remove("loaded");
    }
  }

  function hideOverlay() {
    overlayRef.current = setTimeout(() => {
      const body = document.querySelector("body");
      if (body) {
        body.classList.add("loaded");
      }
      const root = document.querySelector("#root");
      if (root) {
        root.classList.add("loaded");
      }
    }, 500);
  }

  useEffect(
    () => () => {
      clearTimeout(overlayRef.current);
    },
    []
  );

  return { showOverlay, hideOverlay };
}

export default useOverlay;
