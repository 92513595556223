// Get Products

// Refresh Store
export const REFRESH_STORE = 'REFRESH_STORE';

//Quick
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW';
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW';

// Cart
export const CHANGE_QTY = 'CHANGE_QTY';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_LIST = 'UPDATE_LIST';
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_VARIATIONS = 'UPDATE_VARIATIONS';
export const REFRESH_CART_STORE = 'REFRESH_CART_STORE';
export const CHANGE_PAYMENT_CONDITION = 'CHANGE_PAYMENT_CONDITION';

// Notifications
export const SET_UNREAD_NOTIFICATIONS_QTY = 'SET_UNREAD_NOTIFICATIONS_QTY';
export const SET_UPDATE_NOTIFICATIONS_QTY = 'SET_UPDATE_NOTIFICATIONS_QTY';

//Product Filters
export const SORT_BY = 'SORT_BY';
export const SET_FILTER = 'SET_FILTER';
export const SIZE_FILTER = 'SIZE_FILTER';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const RESET_FILTER = 'RESET_FILTER';
export const COLOR_FILTER = 'COLOR_FILTER';
export const BRAND_FILTER = 'BRAND_FILTER';
export const PRICE_FILTER = 'PRICE_FILTER';
export const RATING_FILTER = 'RATING_FILTER';
export const PAYMENT_FILTER = 'PAYMENT_FILTER';
export const CATEGORY_FILTER = 'CATEGORY_FILTER';

//User
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// User admin
export const CHANGE_USER_FRANCHISEE = 'CHANGE_FRANCHISEE'

//Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL';

// Change theme
export const CHANGE_THEME = 'CHANGE_THEME';

