import React from "react";

type ButtonProps = {
  text?: string;
  icon?: string;
  click?: Function;
  submit?: boolean;
  loading?: boolean;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  click,
  submit,
  className,
  loading = false
}: ButtonProps) => (
  <button
    type={submit ? "submit" : "button"}
    className={`btn btn-outline-primary-2 ${className}`}
    disabled={loading}
    onClick={() => click && click()}
  >
    {loading ? (
      <div className="spinner-border text-dark" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    ) : (
      <>
        {text && <span>{text.toUpperCase()}</span>}
        {icon && <i className={icon} />}
      </>
    )}
  </button>
);

export default Button;
