const SESSION_PREFIX = '/session'
const ADMIN_PREFIX = '/admin'
const FRANCHISEE_PREFIX = '/franchisee'

export const LOGIN_URL = SESSION_PREFIX + '/login';
export const LOGOUT_URL = SESSION_PREFIX + '/logout';
export const REFRESH_TOKEN_URL = SESSION_PREFIX + '/refresh';
export const FORGET_PASSWORD_URL = SESSION_PREFIX + '/password';
export const RESET_PASSWORD_URL = SESSION_PREFIX + '/password-reset';
export const VERIFY_PASSWORD_URL = SESSION_PREFIX + '/password-verify';

export const STORES_URL = `${FRANCHISEE_PREFIX}/stores`
export const ORDERS_URL = `${FRANCHISEE_PREFIX}/orders`
export const FOOTER_URL = `${FRANCHISEE_PREFIX}/footer`
export const CONTACT_URL = `${FRANCHISEE_PREFIX}/contact`
export const FILTERS_URL = `${FRANCHISEE_PREFIX}/filters`
export const BRANDS_URL = `${FRANCHISEE_PREFIX}/brands`
export const BRAND_LIST_URL = `${FRANCHISEE_PREFIX}/brandsList`
export const MY_ACCOUNT_URL = `${FRANCHISEE_PREFIX}/myAccount`
export const HOME_NEWS_URL = `${FRANCHISEE_PREFIX}/newProducts`
export const LOGO_HEADER_URL = `${FRANCHISEE_PREFIX}/headerLogo`
export const HOME_SLIDERS_URL = `${FRANCHISEE_PREFIX}/slidersList`
export const STATES_CITIES_URL = `${ADMIN_PREFIX}/statesCities`
export const HOME_BEST_SELLERS_URL = `${FRANCHISEE_PREFIX}/bestSellers`
export const PAYMENT_FILTERS_URL = `${FRANCHISEE_PREFIX}/paymentFilters`
export const HOME_HIGHLIGHTS_URL = `${FRANCHISEE_PREFIX}/highlightsList`
export const NOTIFICATION_URL = `${FRANCHISEE_PREFIX}/notifications`;
export const DOWNLOAD_ORDER_FILE_URL = `${ADMIN_PREFIX}/orders/downloadFile`
export const DOWNLOAD_BRAND_FILE_URL = `${ADMIN_PREFIX}/brands/downloadFile`
export const ORDER_SITUATIONS_URL = `${FRANCHISEE_PREFIX}/orders-situations`
export const PAYMENT_CONDITIONS_URL = `${FRANCHISEE_PREFIX}/payment-conditions`
export const GROUPS_URL = `${FRANCHISEE_PREFIX}/groups`
export const FRANCHISEES_URL = `${FRANCHISEE_PREFIX}/franchisees`

export const HOME_TRAININGS_URL = `${FRANCHISEE_PREFIX}/trainingsList`
export const NEWS_URL = `${FRANCHISEE_PREFIX}/newsList`

export const PRODUCT_DETAILS = `${FRANCHISEE_PREFIX}/productDetails`
export const CART_FRANCHISEES_URL = `${FRANCHISEE_PREFIX}/cartFranchises`
export const UPDATED_PRODUCTS_URL = `${FRANCHISEE_PREFIX}/updateProducts`;
export const PRODUCTS_BY_BRAND_URL = `${FRANCHISEE_PREFIX}/productsByBrand`
