export const errorMessage = (
  typeError: string,
  rules: any,
  typeInput: string,
  message: string = ""
) => {
  switch (typeError) {
    case "required":
      return "Campo obrigatório!";
    case "minLength":
      return `O campo deve que possuir no mínimo ${rules.minLength} caracteres`;
    case "maxLength":
      return `O campo deve que possuir no máximo ${rules.maxLength} caracteres`;
    case "pattern":
      return typeInput === "email" ? "Digite um e-mail válido" : message;
    default:
      return message;
  }
};
