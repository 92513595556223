/* eslint-disable */
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// import store
import store, { persistor } from "./store";

// import routes
import AppRoute from "./routes";

// import Utils
import { initFunctions } from "./utils";

// Import overlay
import LoadingOverlay from "./components/LoadingOverlay";

export function Root() {
  initFunctions();

  useEffect(() => {
    // if (store.getState().modal.current !== 5) {
    //   store.dispatch(refreshStore(5));
    // }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
        <BrowserRouter basename={"/"}>
          <ToastContainer className="toast-container" />
          <AppRoute />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
