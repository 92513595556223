export function setStorage(rememberMe, key, data) {
  if (rememberMe) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
}

export function getStorage(key) {
  let content = sessionStorage.getItem(key);

  if (!content || content.trim() === "") {
    content = localStorage.getItem(key);
  }

  if (content === 'undefined') {
    return null;
  }

  content = JSON.parse(content);

  return content
}

export function updateStorage(key, data) {
  let isStoragedInSession = true;
  let content = sessionStorage.getItem(key);

  if (!content || content.trim() === "") {
    isStoragedInSession = false;
    content = localStorage.getItem(key);
  }

  content = JSON.parse(content);

  content.token = data.token;
  content.refreshToken = data.refreshToken;

  if (isStoragedInSession) {
    sessionStorage.setItem(key, JSON.stringify(content));
  } else {
    localStorage.setItem(key, JSON.stringify(content));
  }
}


export function clearStorage(key) {
  // sessionStorage.setItem(key, null);
  // localStorage.setItem(key, null);
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
}
