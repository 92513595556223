import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { scrollTop } from "../utils";
import ErrorPage from "../pages/404";
import LoginPage from "../pages/Login";
import ResetPasswordPage from "../pages/ResetPassword";
import ForgetPasswordPage from "../pages/ForgetPassword";
import LoadingOverlay from "../components/LoadingOverlay";

// const Others = React.lazy(() => import("./others-route.js"));
const MainPages = React.lazy(() => import("./home-route.js"));
const BrandPage = React.lazy(() => import("./brand-route.js"));
const ProductPage = React.lazy(() => import("./products-route.js"));

function AppRoot() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <React.Suspense fallback={<LoadingOverlay />}>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/login`}
          component={LoginPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/recuperar-senha`}
          component={ForgetPasswordPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/redefinir-senha/:token`}
          component={ResetPasswordPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/marca/:brandSlug`}
          component={BrandPage}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/produto`}
          component={ProductPage}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} component={MainPages} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </React.Suspense>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, null)(AppRoot);
