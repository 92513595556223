/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from "react";
import { useFormContext, FieldValues } from "react-hook-form";

import { applyBrlMask, valueIsNumber } from "../../utils";
import { errorMessage } from "../../utils/errorMessage";

type InputProps = {
  type: string;
  label?: string;
  placeholder?: string;
  id: string;
  name: string;
  className?: string;
  rows?: number;
  rules?: Object;
  disabled?: boolean;
  defaultValue?: number;
  maxValue?: number;
  clbIncrement?: CallableFunction;
  clbDecrement?: CallableFunction;
  forceState?: boolean
  onChange?: any;
  valueState?: any
  maxLength?: any
};

const Input: React.FC<InputProps> = ({
  type,
  label,
  placeholder,
  id,
  name,
  className,
  rules,
  rows,
  defaultValue,
  maxValue,
  maxLength,
  clbIncrement,
  clbDecrement,
  disabled = false,
  forceState = false,
  onChange = () => { },
  valueState = undefined,
}: InputProps) => {

  const methods = useFormContext<FieldValues>();

  function increment() {
    if (forceState && !disabled) {
      onChange("inc");
      return;
    }

    if (clbIncrement) clbIncrement();

    let value = Number(methods.getValues(name));

    if (!maxValue || (maxValue && value < maxValue)) {
      value += 1;
    }

    methods.setValue(name, value);
  }

  function decrement() {
    if (forceState && !disabled) {
      onChange("dec");
      return;
    }

    if (clbDecrement) clbDecrement();

    let value = Number(methods.getValues(name));

    if (value > 0) {
      value -= 1;
      methods.setValue(name, value);
    }
  }

  const applyCurrencyMask = (e: any) => {
    e.persist();
    let { value } = e.target;

    value = applyBrlMask(value, ",");
    methods.setValue(name, `R$ ${value}`);
  };

  const handleQtyChange = (e: any) => {
    e.persist();
    const { value } = e.target;

    if (value === "" || (maxValue && value.length > maxValue)) {
      methods.setValue(name, "");
    }

    if (valueIsNumber(value)) {
      methods.setValue(name, value || Number(value));
    } else {
      methods.setValue(name, methods.getValues(name));
    }
  };

  if (type === "hidden") {
    return (
      <input id={id} name={name} type={type} />
    );
  }

  const applyPhoneNumberMask = (e: any) => {
    e.persist();

    let { value } = e.target;
    value = value.replaceAll(".", "").trim();

    if (value && (value.length > maxLength || Number.isNaN(Number(value)))) {
      methods.setValue(name, methods.getValues(name));
    } else {
      methods.setValue(name, value);

    }

  };

  if (type === "password") {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          type={type}
          className={`${className} ${methods.formState.errors[name] && " is-invalid"}`}
          placeholder={placeholder || ""}
          {...methods.register(name, rules)}
        />
        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {methods.formState.errors[name] &&
            errorMessage(
              methods.formState.errors[name].type,
              rules,
              type,
              methods.formState.errors[name].message
            )}
        </span>
      </>
    );
  }

  if (type === "email") {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={`form-control ${className} ${methods.formState.errors[name] && " is-invalid"}`}
          placeholder={placeholder || ""}
          {...methods.register(name, rules)}
        />
        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {methods.formState.errors[name] &&
            errorMessage(methods.formState.errors[name].type, rules, type)}
        </span>
      </>
    );
  }

  if (type === "textarea") {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <textarea
          id={id}
          className={`form-control ${className} ${methods.formState.errors[name] && " is-invalid"}`}
          rows={rows}
          placeholder={placeholder || ""}
          {...methods.register(name, rules)}
        />
        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {methods.formState.errors[name] &&
            errorMessage(methods.formState.errors[name].type, rules, type)}
        </span>
      </>
    );
  }

  if (type === "qty" && forceState) {
    return (
      <>
        <div className="input-group input-spinner">
          <div className="input-group-prepend">
            <button
              style={{ minWidth: "26px" }}
              className="btn btn-decrement btn-spinner"
              type="button"
              disabled={disabled}
              onClick={decrement}
            >
              <i className="icon-minus" />
            </button>
          </div>
          <input
            id={id}
            name={name}
            type="text"
            disabled={disabled}
            style={{ textAlign: "center" }}
            className="form-control "
            value={valueState}
            onChange={onChange}
            max={maxValue}
          />
          <div className="input-group-append">
            <button
              style={{ minWidth: "26px" }}
              className="btn btn-increment btn-spinner"
              type="button"
              disabled={disabled}
              onClick={increment}
            >
              <i className="icon-plus" />
            </button>
          </div>
        </div>

      </>
    );
  }

  if (type === "qty") {
    return (
      <>
        <div className={`input-group input-spinner ${className}`}>
          <div className="input-group-prepend">
            <button
              style={{ minWidth: "26px" }}
              className="btn btn-decrement btn-spinner"
              type="button"
              onClick={decrement}
            >
              <i className="icon-minus" />
            </button>
          </div>
          <input
            id={id}
            type="text"
            style={{ textAlign: "center" }}
            className="form-control "
            defaultValue={defaultValue}
            placeholder={placeholder || ""}
            max={maxValue}
            {...methods.register(name, rules)}
            onChange={handleQtyChange}
          />
          <div className="input-group-append">
            <button
              style={{ minWidth: "26px" }}
              className="btn btn-increment btn-spinner"
              type="button"
              onClick={increment}
            >
              <i className="icon-plus" />
            </button>
          </div>
        </div>

        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {
            // eslint-disable-next-line max-len
            methods.formState.errors[name] && errorMessage(methods.formState.errors[name].type, rules, type)
          }
        </span>
      </>
    );
  }

  if (type === "currency") {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          type={type}
          placeholder={placeholder || ""}
          {...methods.register(name, rules)}
          onChange={applyCurrencyMask}
          className={`form-control ${className} ${methods.formState.errors[name] && " is-invalid"}`}
        />
        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {
            // eslint-disable-next-line max-len
            methods.formState.errors[name] && errorMessage(methods.formState.errors[name].type, rules, type)
          }
        </span>
      </>
    );
  }

  if (type === "phoneNumber") {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder || ""}
          {...methods.register(name, rules)}
          onChange={applyPhoneNumberMask}
          className={`form-control ${className} ${methods.formState.errors[name] && " is-invalid"}`}
        />
        <span className="label-error" hidden={!methods.formState.errors[name]}>
          {
            // eslint-disable-next-line max-len
            methods.formState.errors[name] && errorMessage(methods.formState.errors[name].type, rules, type, methods.formState.errors[name].message)
          }
        </span>
      </>
    );
  }

  if (forceState) {
    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          type={type}
          value={valueState}
          onChange={onChange}
          placeholder={placeholder || ""}
          className={`form-control ${className}`}
        />
      </>
    );
  }

  // onChange={printar}
  // type="number"
  // name="quantity"
  // id="quantity"
  // defaultValue={item.quantity}
  // min="1"
  // max={"4"}
  // step="1"
  // data-decimals="0"
  // required
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        type={type}
        maxLength={maxLength}
        placeholder={placeholder || ""}
        className={`form-control ${className} ${methods.formState.errors[name] && " is-invalid"}`}
        {...methods.register(name, rules)}
      />
      <span className="label-error" hidden={!methods.formState.errors[name]}>
        {
          // eslint-disable-next-line max-len
          methods.formState.errors[name] && errorMessage(methods.formState.errors[name].type, rules, type)
        }
      </span>
    </>
  );
};

export default Input;
